import localforage from 'localforage';
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2';

export default defineNuxtPlugin(({$pinia}) => {
  $pinia.use(
    createPersistedStatePlugin({
      storage: {
        getItem: async (key) => {
          return localforage.getItem(key)
        },
        setItem: async (key, value) => {
          return localforage.setItem(key, value)
        },
        removeItem: async (key) => {
          return localforage.removeItem(key)
        },
      },
    }),
  )
})
